import { useEffect, cloneElement } from 'react';
import { View } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import makeStyles from 'styles/makeStyles';
// import {
// validateToken as apiValidateToken,
// login as apiLogin,
// } from 'api/auth';
import Auth from 'components/Auth';
import Centered from 'components/ActivityIndicator/Centered';
import {
  setAuthorized, validateToken, authLogin, selectAuth,
} from 'slices';

const styles = makeStyles((theme) => ({
  main: {
    backgroundColor: theme.layoutBodyBackground,
    height: '100%',
    minHeight: '100%',
    fontFamily: 'brandon-grotesque',
  },
}));

const ProtectedPage = ({ children, ...props }) => {
  const { loading: isLoading, authorized: isAuthorized, error } = useSelector(selectAuth);

  const dispatch = useDispatch();

  const doLogin = async (payload) => {
    try {
      dispatch(authLogin(payload));
      // await apiLogin(dispatch)(payload);
      // dispatch(setAuthorized(true));
    }
    catch (ex) {
      dispatch(setAuthorized(false));
    }
  };

  useEffect(() => {
    dispatch(validateToken());
  }, []);

  if (isLoading) {
    return (
      <View
        style={styles.main}
        {...props}
      >
        <Centered isLoading />
      </View>
    );
  }

  if (!isAuthorized) {
    return (
      <Auth onLogin={doLogin} error={error} />
    );
  }

  const child = cloneElement(children, { isLogged: isAuthorized });

  return (
    <View
      style={styles.main}
      {...props}
    >
      {child}
    </View>
  );
};

export default ProtectedPage;
