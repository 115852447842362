import { ActivityIndicator as Indicator } from 'react-native';
import theme from 'styles/theme';

const { secondaryColor } = theme;

const DefaultIndicator = ({ isLoading = false, size = 'large' }) => {
  if (!isLoading) {
    return null;
  }

  return (
    <Indicator
      size={size}
      color={secondaryColor}
    />
  );
};

export default DefaultIndicator;
