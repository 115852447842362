import { useState } from 'react';
import {
  View, ScrollView, TouchableHighlight,
} from 'react-native';
import Text from 'components/Text';
import TextInput from 'components/TextInput';
import { useStyles } from 'styles/makeStyles';
import SociavoreLogo from 'assets/sociavore_logo.svg';
import BoltIcon from 'assets/bolt_black_24dp.svg';

const stylesFn = (theme) => ({
  main: {
    display: 'flex',
    // alignItems: 'center',
    height: '100%',
    ...theme.lgMin({
      paddingTop: theme.spacing(40),
      padding: theme.spacing(4),
      backgroundColor: '#F3F3F3',
    }),
  },
  panel: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },
  input: {
    ...theme.border(1, 'solid', '#bebebe'),
    backgroundColor: 'white',
    borderRadius: 2,
    paddingHorizontal: theme.spacing(3),
    paddingVertical: theme.spacing(2),
    fontSize: 14,
    fontWeight: 500,
    color: '#555',
    marginBottom: theme.spacing(5),
    width: '100%',
    textTransform: 'lowercase',
  },
  geogrotesque: {
    fontFamily: 'Geogrotesque',
  },
  font: {
    fontWeight: 900,
    fontSize: 24,
    textTransform: 'uppercase',
    color: '#555',
  },
  login: {
    justifyContent: 'flex-start',
    // alignItems: 'center',
    backgroundColor: '#FBFBFB',
    paddingHorizontal: theme.spacing(4),
    maxWidth: 800,
    width: '100%',
    paddingVertical: theme.spacing(30),
    ...theme.mdMin({
      borderRadius: theme.spacing(2),
      paddingHorizontal: theme.spacing(10),
    }),
    ...theme.lgMin({
      paddingHorizontal: theme.spacing(30),
    }),
    // justifyContent: 'center',
  },
  loginTitle: {
    textTransform: 'uppercase',
    fontWeight: 700,
  },
  logo: {
    color: theme.warningColor,
    textTransform: 'uppercase',
    fontWeight: 700,
    marginBottom: theme.spacing(10),
    // alignSelf: 'flex-start',
  },
  imageLogo: {
    height: 100,
    width: 100,
  },
  title: {
    fontSize: 24,
    fontWeight: 700,
    // alignSelf: 'flex-start',
    textTransform: 'uppercase',
    marginBottom: theme.spacing(1),
    color: 'black',
  },
  button: {
    backgroundColor: theme.warningColor,
    borderRadius: 2,
    textTransform: 'capitalize',
    paddingHorizontal: theme.spacing(3),
    paddingVertical: theme.spacing(2),
    textAlign: 'center',
  },
  label: {
    color: '#7F7F7F',
    fontWeight: 700,
    // alignSelf: 'flex-start',
    marginBottom: theme.spacing(6),
  },
  loginButton: {
    width: '100%',
    marginBottom: theme.spacing(5),
  },
  passwordActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  link: {
    textDecorationLine: 'underline',
    color: 'black',
  },
  boltIcon: {
    opacity: 0,
    ...theme.mdMin({
      opacity: 1,
    }),
  },
  errorMessage: {
    border: `1px solid ${theme.errorColor}`,
    padding: theme.spacing(3),
    marginBottom: theme.spacing(5),
    textAlign: 'center',
  },
  errorMessageFont: {
    color: theme.errorColor,
  },

});

const Auth = ({ onLogin, error }) => {
  const styles = useStyles(stylesFn);
  const [email, setEmail] = useState();
  const [pass, setPass] = useState();

  const doLogin = () => {
    onLogin({ email, password: pass });
  };

  const doSignup = () => {
    window.location.href = 'https://scvr.co/users/setup';
  };

  const doForgot = () => {
    window.location.href = 'https://scvr.co/users/password/new';
  };

  const errorMessage = error ? (
    <View style={styles.errorMessage}>
      <Text style={styles.errorMessageFont}>Email or password is invalid</Text>
    </View>
  ) : null;

  return (
    <ScrollView style={styles.main}>
      <View style={styles.panel}>
        <View style={styles.login}>

          <SociavoreLogo viewBox="0 0 1600 112" style={{ marginBottom: 16, alignItems: 'center' }} />

          <View style={{
            flexDirection: 'row', alignItems: 'center', width: '100%', marginLeft: -36,
          }}
          >
            <View style={styles.boltIcon}>
              <BoltIcon viewBox="0 0 24 24" width="36" height="36" fill="#F7B500" />
            </View>
            <Text style={[styles.title, styles.geogrotesque]}>Lightning Dashboard</Text>
          </View>

          <Text style={[styles.label, styles.geogrotesque]}>LOGIN</Text>

          <TextInput
            onChangeText={setEmail}
            placeholder="EMAIL ADDRESS"
            style={[styles.input, styles.geogrotesque]}
            value={email}
            keyboardType="email-address"
          />

          <TextInput
            onChangeText={setPass}
            placeholder="PASSWORD"
            password
            style={[styles.input, styles.geogrotesque]}
            value={pass}
          />

          {errorMessage}

          <TouchableHighlight
            onPress={doLogin}
            style={styles.loginButton}
          >
            <View style={styles.button}>
              <Text style={[styles.loginTitle, styles.geogrotesque]}>Log in</Text>
            </View>

          </TouchableHighlight>

          <View style={styles.passwordActions}>

            <TouchableHighlight
              onPress={doSignup}
            >
              <Text style={[styles.link, styles.geogrotesque]}>Don&apos;t have an account?</Text>
            </TouchableHighlight>

            <TouchableHighlight
              onPress={doForgot}
            >
              <Text style={[styles.link, styles.geogrotesque]}>Forgot password?</Text>
            </TouchableHighlight>

          </View>

        </View>
      </View>
    </ScrollView>
  );
};

export default Auth;
