import { View, TouchableOpacity } from 'react-native';
import Text from 'components/Text';
import LinearGradient from 'react-native-linear-gradient';
import makeStyles from 'styles/makeStyles';

const ph = 22;
const pv = 8;

const styles = makeStyles((theme) => ({
  button: {
    ...theme.border(1, 'solid', theme.borderColorBase),
    borderRadius: theme.borderRadiusSm,
    marginHorizontal: theme.spacing(2),
    overflow: 'hidden',
  },
  font: {
    fontWeight: 500,
    fontSize: 15,
    textTransform: 'uppercase',
  },
  defaultFont: {
    color: theme.secondaryColor,
  },
  defaultFontDisabled: {
    color: theme.textDisabledColor,
  },
  defaultBg: {},
  primaryBg: {
    backgroundColor: theme.primaryColor,
    border: 'none',
  },
  primaryFont: {
    color: 'white',
  },
  primaryBgDisabled: {
    backgroundColor: '#CCC', // theme.primaryColor,
    border: 'none',
  },
  primaryFontDisabled: {
    color: 'white',
  },
  secondaryFont: {
    color: 'white',
  },
  secondaryBg: {
    backgroundColor: theme.secondaryColor,
    border: 'none',
  },
  secondaryBgDisabled: {
    backgroundColor: '#757b88',
    border: 'none',
  },
  secondaryFontDisabled: {
    color: theme.textDisabledColor,
  },
  cancelBg: {
    backgroundColor: theme.cancelColor,
    border: 'none',
  },
  cancelFont: {
    color: theme.secondaryColor,
  },
  linkBg: {
    padding: 0,
    border: 'none',
  },
  linkFont: {
    fontSize: theme.fontSizeBase,
    textDecorationLine: 'underline',
    textTransform: 'none',
    fontWeight: 0,
  },
  linkCleanFont: {
    fontSize: theme.fontSizeBase,
  },
  icon: {
    marginRight: theme.spacing(4),
  },

}));

const VARIANTS = {
  action: ['actionFont', 'actionBg'],
  cancel: ['cancelFont', 'cancelBg'],
  default: ['defaultFont', 'defaultBg', 'defaultFontDisabled', 'defaultBgDisabled'],
  link: ['linkFont', 'linkBg'],
  linkClean: ['linkCleanFont', 'linkBg'],
  primary: ['primaryFont', 'primaryBg', 'primaryFontDisabled', 'primaryBgDisabled'],
  secondary: ['secondaryFont', 'secondaryBg', 'secondaryFontDisabled', 'secondaryBgDisabled'],
};
const GRADS = {
  default: { colors: [] },
  primary: { colors: ['#fc9254', '#fa6a73'], angle: 90, useAngle: true },
  secondary: { colors: [] },
  cancel: { colors: [] },
  action: { colors: [] },
  link: { colors: [] },
};

const Button = ({
  children,
  disabled,
  disabledPress,
  fontStyle,
  hidden,
  icon,
  onPress,
  paddingHorizontal = ph,
  paddingVertical = pv,
  rootStyle,
  style,
  variant = 'default',
}) => {
  if (hidden) {
    return null;
  }

  const wrappedIcon = icon ? (<View style={styles.icon}>{icon}</View>) : null;
  const [variantFontActive, variantBgActive, variantFontDisabled = {}, variantBgDisabled = {}] = VARIANTS[variant];
  const variantBg = disabled ? variantBgDisabled : variantBgActive;
  const variantFont = disabled ? variantFontDisabled : variantFontActive;

  const title = (
    <>
      {wrappedIcon}
      <Text style={[styles.font, fontStyle, styles[variantFont]]}>{children}</Text>
    </>
  );

  const { [variant]: grad } = GRADS;

  const gradComp = disabled ? title : (
    <LinearGradient
      start={{ x: 0, y: 0 }}
      end={{ x: 1, y: 0 }}
      style={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        paddingHorizontal,
        paddingVertical,
        textAlign: 'center',
        justifyContent: 'center',
      }}
      {...grad}
    >
      {title}
    </LinearGradient>
  );

  const body = (
    <View style={[
      styles.button,
      styles[variantBg],
      disabledPress ? { cursor: 'default' } : {},
      { textAlign: 'center' },
      style,
      { /* backgroundColor: 'none', */ paddingHorizontal: 0, paddingVertical: 0 },
    ]}
    >
      {gradComp}
    </View>
  );

  return (
    <TouchableOpacity
      onPress={disabledPress ? () => null : onPress}
      disabled={disabled}
      style={rootStyle}
    >
      {body}
    </TouchableOpacity>
  );
};

export default Button;
