import { useState } from 'react';
import { View, TextInput } from 'react-native';
import Text from 'components/Text';
import { useStyles } from 'styles/makeStyles';
import theme from 'styles/theme';
import { mergeStyles } from 'utils';
import Label from 'components/TextInput/Label';

const { fontFamily, fontSizeBase, lineHeightBase } = theme;

const fontStyle = {
  fontFamily,
  fontSize: fontSizeBase,
  lineHeight: lineHeightBase,
};

const stylesFn = (theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    borderRadius: theme.borderRadiusInput,
    height: 48,
    // backgroundColor: 'lime',
    ...theme.border(1, 'solid', theme.borderColorLight),
  },
  input: {
    width: '100%',
    height: '100%',
    ...theme.inputDefault,
  },
  addon: {
    width: 48,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...theme.borderRight(1, 'solid', theme.borderColorLight),
  },
  leftAddonIcon: {
    ...theme.borderRight(1, 'solid', theme.borderColorLight),
    backgroundColor: theme.cancelColor,
  },
  addonText: {
    fontSize: 14,
    fontWeight: 'bold',
    color: theme.textInputAccentColor,
  },
  withLeftAddon: {
    // borderBottomLeftRadius: 0,
    // borderTopLeftRadius: 0,
  },
  placeholder: {
    fontWeight: 500,
  },
});

const CustomTextInput = ({
  hidden,
  inputStyle,
  label,
  leftAddonIcon,
  leftAddonText,
  onFocus,
  password,
  placeholder,
  style,
  sublist = null,
  value = '',
  ...props
}) => {
  if (hidden) {
    return null;
  }

  const [focus, setFocus] = useState();
  const styles = useStyles(stylesFn);
  const mergedStyle = mergeStyles(fontStyle, style);

  const labelComp = label ? (<Label>{label}</Label>) : null;
  const isEmpty = (value === undefined || value === null || value === '');
  const showPlaceholder = !focus && isEmpty;
  const hasLeftAddon = !!leftAddonIcon || !!leftAddonText;

  let leftAddon = null;
  if (leftAddonIcon) {
    leftAddon = (
      <View style={[styles.addon, styles.leftAddonIcon]}>
        {leftAddonIcon}
      </View>
    );
  }
  else if (leftAddonText) {
    leftAddon = (
      <View style={[styles.addon]}>
        <Text style={styles.addonText}>{leftAddonText}</Text>
      </View>
    );
  }

  const hidePasswordStyle = (password && !isEmpty) ? { WebkitTextSecurity: 'square' } : {};

  return (
    <>
      {labelComp}
      <View style={[styles.root, mergedStyle]}>
        {leftAddon}
        <TextInput
          onFocus={() => {
            if (onFocus) onFocus();
            setFocus(true);
          }}
          onBlur={() => setFocus(false)}
          style={[styles.input, hasLeftAddon ? styles.withLeftAddon : {}, isEmpty ? styles.placeholder : {}, inputStyle, { outlineWidth: 0 }, hidePasswordStyle]}
          value={showPlaceholder ? placeholder : value}
          {...props}
        />
        {sublist}
      </View>
    </>
  );
};

export default CustomTextInput;
