import {Dimensions} from 'react-native';

export const XS_MAX=300;
export const SM_MAX=600;
export const MD_MAX=960;
export const LG_MAX=1280;
export const XL_MAX=1920;

export const XS_MIN=0;
export const SM_MIN=300;
export const MD_MIN=600;
export const LG_MIN=960;
export const XL_MIN=1280;

export const FONT_SIZE_BASE = 16;
export const SECONDARY_COLOR = '#354150';
//export const SECONDARY_COLOR = '#454b58';
export const DISABELED_COLOR = '#ACACAC';
export const TEXT_INPUT_COLOR='#6A6F7A';
export const TEXT_INPUT_ACCENT_COLOR='#28384f';
export const FONT_FAMILY = 'brandon-grotesque';
export const SPACE = 4;

const borderFn = (position, width, style, color) => {
  const widthKey = `border${position}Width`;
  const styleKey = `border${position}Style`;
  const colorKey = `border${position}Color`;
  return{
    [widthKey]: width,
    [styleKey]: style,
    [colorKey]: color,
  }
};

const generateMin = (BREAKPOINT, dimsFn) => {
  return (styles)=>{
    const dims = dimsFn();
    if(dims.width > BREAKPOINT) {
      return styles;
    }
    return {}
  }
}

export const buildTheme = (dims) =>  {
  if(!dims){ dims = Dimensions.get('window'); }
  const VH = dims.height/100;
  const VW = dims.width/100;

  const dimsFn = ()=>dims;

  const smMin = generateMin(SM_MIN, dimsFn);
  const mdMin = generateMin(MD_MIN, dimsFn);
  const lgMin = generateMin(LG_MIN, dimsFn);
  const xlMin = generateMin(XL_MIN, dimsFn);
  const spacing = mult => mult * SPACE;
  const borderColorBase = '#DDE0E3';
  const borderColorLight = '#E4E9EC';
  const border = (width, style, color) => borderFn('', width, style, color);

  return({
    VH,
    /* COLORS */
    primaryColor: '#E75230',// '#FC9254', //'#474B56',
    disabledColor: '#989898',
    normalColor: '#E4E9EC',
    normalAccentColor: '#EAECED',
    paleColor: '#8090A3',
    cancelColor: '#EFF3F6',
    secondaryColor: SECONDARY_COLOR,
    textColor: SECONDARY_COLOR,
    textDisabledColor: DISABELED_COLOR,
    textInputColor: TEXT_INPUT_COLOR,
    textInputAccentColor: TEXT_INPUT_ACCENT_COLOR,
    successColor: '#009C7C',
    warningColor: '#F7B500',
    errorColor: '#E02020',
    archivedColor: DISABELED_COLOR,
    futureColor: '#4B0082',
    highlightColor: '#FFF5F0',
    layoutDarkColor: '#1E2432',
    blueInkColor: '#4990E2',
    dangerColor: '#BF4E33',
    inputBgColor: '#EFF3F680',

    layoutHeaderBackground: '#222936',
    layoutBodyBackground: '#F6F6F8',//'#EFF3F6',

    /* FONTS */
    fontFamily: FONT_FAMILY,
    fontSizeBase: FONT_SIZE_BASE,
    fontSizeLg: FONT_SIZE_BASE + 2,
    fontSizeSm: 14,
    fontWeightNormal: 400,
    fontWeightBold: 700,

    /* SPACING */
    spacing,
    rems: mult=> mult * SPACE*4,
    vh: mult=> mult * VH,
    vw: mult=> mult * VW,


    /* BORDERS */
    borderColorBase,
    borderColorLight,

    /* BORDERS FN */
    borderTop: (width, style, color) => borderFn('Top', width, style, color),
    borderBottom: (width, style, color) => borderFn('Bottom', width, style, color),
    borderLeft: (width, style, color) => borderFn('Left', width, style, color),
    borderRight: (width, style, color) => borderFn('Right', width, style, color),
    border,

    /* OTHER SIZES */
    lineHeightBase: FONT_SIZE_BASE * 1.5,
    borderRadiusBase: 8,
    borderRadiusSm: 4,
    borderRadiusInput: 4,
    topbarHeight: 82,

    smMin,
    mdMin,
    lgMin,
    xlMin,

    abs: (top=0, right=0, bottom=0, left=0) => ({
      position: 'absolute',
      top,
      right,
      bottom,
      left,
    }),

    lgMinCol: {
      flexDirection: 'column',
      ...lgMin({flexDirection: 'row'}),
    },
    lgMinBlock: (display) => ({
      display: 'none',
      ...lgMin({display}),
    }),
    xlMinCol: {
      flexDirection: 'column',
      ...xlMin({flexDirection: 'row'}),
    },
    xlMinBlock: (display) => ({
      display: 'none',
      ...xlMin({display}),
    }),
    mdMinCol: {
      flexDirection: 'column',
      ...mdMin({flexDirection: 'row'}),
    },
    mdMinBlock: (display) => ({
      display: 'none',
      ...mdMin({display}),
    }),
    mdMinHide: (display)=>({
      display,
      ...mdMin({display: 'none'}),
    }),
    pageBox: {
      paddingHorizontal: spacing(2),
      ...lgMin({
        paddingHorizontal: 40,
      }),
    },
    inputDefault: {
      //borderRadius: 8,
      display: 'flex',
      justifyContent: 'center',
      paddingHorizontal: spacing(3),
      paddingVertical: spacing(2),
      color: TEXT_INPUT_COLOR,
      fontFamily: FONT_FAMILY,
      fontSize: 16,
      fontWeight: 500,
      //...border(1, 'solid', borderColorLight),
    },
    inputDefaultText: {
      fontWeight: 500,
      fontSize: 16,
      color: TEXT_INPUT_COLOR,
    },

  });
}

const defaultDims = Dimensions.get('window');
const theme = buildTheme(defaultDims);

export default theme;
