import { Text } from 'react-native';
import theme from 'styles/theme';
import { mergeStyles } from 'utils';

const { fontFamily, fontSizeBase, lineHeightBase } = theme;

const fontStyle = {
  color: theme.textColor,
  fontFamily,
  fontSize: fontSizeBase,
  lineHeight: lineHeightBase,
};

const CustomText = ({ style, hidden, ...props }) => {
  if (hidden) {
    return null;
  }

  const mergedStyle = mergeStyles(fontStyle, style);

  return (
    <Text
      style={mergedStyle}
      {...props}
    />
  );
};

export default CustomText;
