import {useContext, useMemo} from 'react';
import {StyleSheet} from 'react-native';
import theme, {buildTheme} from 'styles/theme';
import DimensionContext from 'components/DimensionObserver/Context';

const makeStyles = (styleFn) => {
  const styles = styleFn(theme);
  return StyleSheet.create(styles);
};

export const useTheme = (dims) => {
  const theme2 = useMemo(()=>buildTheme(dims), [dims]);
  return theme2;
}

export const useStyles = (styleFn) => {
  const {dimensions} = useContext(DimensionContext);
  const theme2 = useTheme(dimensions);
  const styles = styleFn(theme2);
  return StyleSheet.create(styles);
};

export default makeStyles;
