import { View } from 'react-native';
import makeStyles from 'styles/makeStyles';
import Default from './Default';

const styles = makeStyles(() => ({
  centered: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const CenteredIndicator = ({ isLoading = false, size, position = 'fixed' }) => {
  if (!isLoading) {
    return null;
  }

  return (
    <View
      style={[styles.centered, { position }]}
    >
      <Default
        {...{ isLoading, size }}
      />
    </View>
  );
};

export default CenteredIndicator;
