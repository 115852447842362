import Text from 'components/Text';
import { useStyles } from 'styles/makeStyles';

const stylesFn = (theme) => ({
  label: {
    fontWeight: 'bold',
    textTransform: 'capitalize',
    marginBottom: theme.spacing(2),
  },
});

const Label = ({ children, style: externalStyle }) => {
  const styles = useStyles(stylesFn);
  return (<Text style={[styles.label, externalStyle]}>{children}</Text>);
};

export default Label;
