export const mergeStyles = (base, override) => {
  const type = typeof override;
  const isArray = Array.isArray(override);
  const isObject = type === 'object';
  const isNumber = type === 'number';

  if (isArray) {
    return [base, ...override];
  } if (isNumber) {
    return [base, override];
  } if (isObject) {
    return { ...base, ...override };
  }
};

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);
